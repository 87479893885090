import type { ContentType, Sector, Bevoegdheid, Onderwijsvorm } from '~~/types'



export const useConstants = () => {

    const sectors: Sector[] = [
        { id: 'po', label: 'Primair onderwijs', altNames: ['Basisonderwijs', 'Primair onderwijs', 'po'] },
        { id: 'vo', label: 'Voortgezet onderwijs', altNames: ['Middelbare school', 'Voortgezet onderwijs', 'vo'] },
        { id: 'so', label: 'Speciaal onderwijs', altNames: ['Speciaal onderwijs', 'so'] },
        { id: 'mbo', label: 'Middelbaar beroepsonderwijs', altNames: ['Middelbaar beroepsonderwijs', 'mbo'] },
        { id: 'ho', label: 'Hoger onderwijs', altNames: ['Hoger onderwijs', 'ho'] },
        // { id: 'wo', label: 'Wetenschappelijk onderwijs', altNames: ['Wetenschappelijk onderwijs', 'wo', 'WO', 'Wo'] },
    ]

    const findSector = (val: string): Sector | undefined => {
        return sectors.find((s) => s.altNames.includes(val) || s.altNames.map(n => n.toLowerCase()).includes(val))
    }

    const bevoegdheden: Bevoegdheid[] = [
        { id: 'po', label: 'Primair onderwijs', altNames: ['po', 'Primair onderwijs', 'Basisonderwijs'], description: 'Met deze bevoegdheid mag lesgeven als groepsleerkracht in het primair onderwijs.' },
        { id: 'vo-1', label: 'Eerstegraads', altNames: ['Eerstegraads', 'Eerstegraads bevoegdheid', 'vo-1'], description: 'Met een eerstegraads lesbevoegdheid mag je lesgeven in het voortgezet onderwijs (vmbo, havo en vwo), het mbo en als vakleerkracht in het primair onderwijs.' },
        { id: 'vo-2', label: 'Tweedegraads', altNames: ['Tweedegraads', 'Tweedegraads bevoegdheid', 'vo-2'], description: 'Met een tweedegraads lesbevoegdheid mag je lesgeven in het voortgezet onderwijs (het hele vmbo en de onderbouw van havo en vwo), het mbo en als vakleerkracht in het primair onderwijs.' },
        { id: 'vo-2-alt', label: 'Beperkt tweedegraads', altNames: ['Beperkt tweedegraads', 'Beperkt tweedegraads bevoegdheid', 'vo-2-alt'], description: 'Met een beperkt tweedegraads lesbevoegdheid mag je lesgeven in het voortgezet onderwijs (vmbo en de onderbouw van havo en vwo) en als vakleerkracht in het primair onderwijs.' },
    ]

    const findBevoegdheid = (val: string): Bevoegdheid | undefined => {
        return bevoegdheden.find((s) => s.altNames.includes(val) || s.altNames.map(n => n.toLowerCase()).includes(val))
    }

    const onderwijsvormen: Onderwijsvorm[] = [
        { id: 'bol', label: 'Voltijd', altNames: ["Voltijd / BOL", "Voltijd", "BOL", "Voltijd/BOL"], description: 'een fulltime opleiding, meestal overdag, vijf dagen per week.' },
        { id: 'deeltijd', label: 'Deeltijd', altNames: ['Parttime', 'Deeltijd', 'part-time'], description: 'Opleiding naast werk of andere verplichtingen, vaak in de avonduren.' },
        { id: 'thuis', label: 'Thuisstudie', altNames: ['Thuis', 'Thuisstudie'], description: 'Flexibele opleiding die je (onlione) vanuit huis volgt, vaak zonder vaste lesuren of locatie.' },
        { id: 'zib', label: 'Zij-instroom', altNames: ['Zij-instroom in het beroep', 'Zij-instroom', 'ZIB'], description: 'Opleiding voor professionals die willen overstappen naar het onderwijs. Bevat vaak vrijstellingen voor eerder verworven competenties.' },
        { id: 'bbl', label: 'Duaal', altNames: ['BBL', "Duaal / BBL", 'Duaal', 'Duaal/BBL'], description: 'Werken en studeren wordt gecombineerd. Anders dan bij een deeltijd studie, is een bij de opleiding passende werkplek verplicht.' },
    ]

    const findOnderwijsVorm = (val: string): Onderwijsvorm | undefined => {
        return onderwijsvormen.find((s) => s.altNames.includes(val) || s.altNames.map(n => n.toLowerCase()).includes(val))
    }


    const icons = {
        opleiding: 'i-heroicons-academic-cap-20-solid',
        opleidingen: 'i-heroicons-academic-cap-20-solid',
        opleider: 'i-heroicons-building-office-2-20-solid',
        opleiders: 'i-heroicons-building-office-2-20-solid',
        onderwijsinstelling: 'i-heroicons-building-office-2-20-solid',
        onderwijsinstellingen: 'i-heroicons-building-office-2-20-solid',
        regioloket: 'i-heroicons-building-storefront-20-solid',
        regioloketten: 'i-heroicons-building-storefront-20-solid',
        school: 'i-heroicons-building-library-20-solid',
        scholen: 'i-heroicons-building-library-20-solid',

        job: 'i-heroicons-briefcase-20-solid',
        event: 'i-heroicons-calendar-date-range-20-solid',


        opleiding_sm: 'i-heroicons-academic-cap-16-solid',
        opleider_sm: 'i-heroicons-building-office-2-16-solid',
        regioloket_sm: 'i-heroicons-building-storefront-16-solid',
        school_sm: 'i-heroicons-building-library-16-solid',



        navigator: 'i-material-symbols:contact-emergency',
        home: 'i-heroicons-home-20-solid',
        gps: 'i-mdi-crosshairs-gps',
        map: 'i-heroicons-map-pin-20-solid',
        contact: 'i-heroicons-envelope-20-solid'
    } as const


    const contentTypes: ContentType[] = [
        { id: 'opleiding', index: 'opleidingen', label: { single: 'opleiding', plural: 'opleidingen' }, icon: icons.opleiding },
        { id: 'onderwijsinstelling', index: 'onderwijsinstellingen', label: { single: 'opleider', plural: 'opleiders' }, icon: icons.opleider },
        { id: 'regioloket', index: 'regioloketten', label: { single: 'regioloket', plural: 'regioloketten' }, icon: icons.regioloket },
        // { id: 'school', index: 'scholen', label: { single: 'school', plural: 'scholen' } },  
    ]   
    

    return { contentTypes, sectors, findSector, bevoegdheden, findBevoegdheid, onderwijsvormen, findOnderwijsVorm, icons };
}